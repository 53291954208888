import React, { useEffect, useState } from 'react'
import './index.scss'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-scroll-parallax'

import Seo from '../components/seo'

import MainFrameCover from '../components/MainFrameCover'
import Intro from '../animations/Intro'
import { useInView } from 'react-intersection-observer'

import SocialLinks from '../components/SocialLinks'
import SanitySlider from '../Sanity/SanitySlider'
import SanityVideo from '../Sanity/SanityVideo'

import Marquee from '../components/Marquee'
import { GatsbyImage } from 'gatsby-plugin-image'
// import Modal from '../components/Modal'
import Footer from '../components/Footer'
// import Typewriter from 'typewriter-effect'


function Page(props) {
  const { data } = props

  const [refHero, refHeroInView] = useInView({ triggerOnce: true })
  const [refTicketsSection, refTicketsSectionInView] = useInView({ triggerOnce: true })
  const [refTicketsVideo, refTicketsVideoInView] = useInView({ triggerOnce: true })
  const [refTicketsImage, refTicketsImageInView] = useInView({ triggerOnce: true })
  const [refAbout, refAboutInView] = useInView({ triggerOnce: true })
  const [refPartners, refPartnersInView] = useInView({ triggerOnce: true })
  const [refFoundation, refFoundationInView] = useInView({ triggerOnce: true })

  const sanityPage = data?.sanityPageHome
  const quickHTML = sanityPage?.quickHTML?.code


  const partners = sanityPage?.partners?.map((node, i) => {
    return (
      <div key={i} className='padd single-partner span-3 span-4-mobile span-4-tablet'>
        <Intro visible={refPartnersInView} in={{ bg: 500, fade: 500 }} delayIn={500 + 50 * i} mounted={true} stay={true} className="c5 t">
          <div className='flex '>
            {/* <SanityImage  {...partner} /> */}
            <Intro visible={refPartnersInView} in={{ fadeBottom: 700 }} delayIn={500 + 50 * i} mounted={true} stay={true} className=" c5 aspect-ratio">
              <GatsbyImage
                image={node?.image?.asset?.gatsbyImageData}
                alt={`${node.title}`}
                layout="fixed"
                objectFit="contain"
                objectPosition={'50% 50%'}
                placeholder='NONE'
              />
            </Intro>
          </div >
        </Intro>
      </div>
    )
  })

  // Keeping track of height of header and viewport so that we can keep the hero around viewport
  // const [slideHeight, setSlideHeight] = useState(0)
  // const textSpace = '(1em + 2vw)'
  // useEffect(() => {
  //   // Checking window size, dropping values into state
  //   function updateSize() {
  //     const header = document?.getElementById('fitheight')
  //     const height = 'calc(' + (window.innerHeight - header.offsetHeight) + 'px - ' + textSpace + ')'
  //     setSlideHeight(height)
  //   }
  //   window.addEventListener('resize', updateSize)
  //   updateSize()

  //   // Kill off listener
  //   return () => window.removeEventListener('resize', updateSize)
  // },[])


  return (
    <>

      <MainFrameCover />

      {/* <Modal trigger={"#modal-1st-login"}>
        <LoginBox />
      </Modal>

      <Modal trigger={"#modal-1st-subscribe"}>
        <SubscribeBox />
      </Modal> */}

      <Marquee {...data?.sanityPageHome} />

      <main id="mainframe" className={`active main-home`}>

        <Seo title={"Home"} siteTitle={'18th Annual Irie Weekend'} description={"18th Annual Irie Weekend"} path={props.location?.pathname ? props.location?.pathname : ''} />

        <section ref={refHero} className="section-hero c0 padd-half padd-bottom-2 flex ac-center buy-tickets-follows">

          <div id={'fitheight'} className="flex-12">

            <div className="hero-text-img span-3 span-12-mobile span-7-tablet flex padd-half  ac-center ai-center">
              <Intro visible={refHeroInView} in={{ fadeRight: 700 }} delayIn={400} mounted={true} stay={true} className="hero-logo overflow-visible">
                <>
                  <div className="aspect-ratio">
                    <StaticImage
                      src={'../../static/images/hero_txt_2.png'}
                      layout="fixed"
                      objectFit="contain"
                      objectPosition={'0% 50%'}
                      placeholder='NONE'
                      alt="18th Annual Irie Weekend Powered by Pepsi"
                    />
                  </div>
                </>
              </Intro>
              <div className='social-container'>
                <Intro visible={refHeroInView} in={{ fadeRight: 700 }} delayIn={500} mounted={true} stay={true} className="overflow-visible stay-connected-wrap">
                  <h1 className='h6 stay-connected'>Stay Connected</h1>
                </Intro>

                <SocialLinks />
              </div>
            </div>

            <div className="span-9 span-12-mobile span-12-tablet overflow-hidden padd-right-half padd-left-half as-center">
              <Intro visible={refHeroInView} in={{ bg: 750, fade: 750 }} delayIn={600} mounted={true} stay={true} className="video-box c5 t">
                <SanitySlider {...sanityPage?.hero_slider[0]} />
              </Intro>
            </div>
          </div>

        </section>
        <section ref={refTicketsVideo} className="section-video-bar flex-12">
          <div className='span-12 span-12-mobile flex-12 jc-flex-end'>
            <Intro visible={refTicketsVideoInView} in={{ fadeBottom: 750 }} delayIn={0} mounted={true} stay={true}>
              <SanityVideo {...data?.sanityPageHome?.section_video?.[0]} />
            </Intro>
          </div>
        </section>

        {refTicketsSectionInView ?
            <a className='section-header-hero buytix' href="mailto:info@iriefoundation.org" target="_blank" rel="noreferrer noopener">
              <div className='light-black'>
                <Intro visible={refTicketsSectionInView} in={{ fadeBottom: 490 }} delayIn={0} mounted={true} stay={true} className="overflow-visible intro">
                  {/* BUY TICKETS */}
                  {/* <div className="aspect-ratio"> */}
                      <StaticImage
                        src={'../../static/images/buytix.jpg'}
                        // layout="fixed"
                        objectFit="contain"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt="Buy Tickets"
                      />
                    {/* </div> */}
                </Intro>
              </div>
            </a>
          : null}

        <section ref={refTicketsSection} className="section-tickets padd-bottom-half c4">

          {/* {refTicketsSectionInView ?
            <a className='section-header-hero buytix' href="mailto:info@iriefoundation.org" target="_blank" rel="noreferrer noopener">
              <h1 className='light-black'>
                <Intro visible={refTicketsSectionInView} in={{ fadeBottom: 490 }} delayIn={0} mounted={true} stay={true} className="overflow-visible intro">
                  BUY TICKETS
                </Intro>
              </h1>
            </a>
            : null} */}

          <div className="flex-12 padd-half">
            {/* <div ref={refTicketsVideo} className='see-how-we-do-it-video span-12 span-12-mobile padd-half flex-12 jc-flex-end'>
              <h2 className="span-4 span-12-mobile see-how-we-do-it-heading aqua-blue">
                <Intro visible={refTicketsVideoInView} in={{ fadeBottom: 490 }} delayIn={0} mounted={true} stay={true} className="overflow-visible">
                  See how<br/>we do it
                </Intro>
              </h2>
              <Intro visible={refTicketsVideoInView} in={{ fadeBottom: 750 }} delayIn={0} mounted={true} stay={true} className="span-8 span-12-mobile">
                <SanityVideo {...data?.sanityPageHome?.section_video?.[0]} />
              </Intro>
            </div> */}
            <div ref={refTicketsImage} className='flex-12'>
              <div className='span-6 span-12-mobile overflow-hidden padd-half'>
                <Intro switch={refTicketsImageInView} in={{ bg: 750, fade: 750 }} delayIn={600} mounted={true} stay={true} className="video-box c5 t">
                  <SanitySlider {...sanityPage?.section_tickets?.[0]} />
                </Intro>
              </div>
              <div className='span-6 span-12-mobile overflow-hidden padd-half'>
                <Intro switch={refTicketsImageInView} in={{ bg: 750, fade: 750 }} delayIn={750} mounted={true} stay={true} className="video-box c5 t">
                  <SanitySlider {...sanityPage?.section_tickets?.[1]} initialDelay={2000} />
                </Intro>
              </div>
            </div>
          </div>

        </section>

        <section ref={refAbout} className="section-about padd-half c0">

          <div className="flex-12 padd-half padd-right-off padd-top-off">
            <div className='span-3 span-12-mobile '>
              <Intro visible={refAboutInView} in={{ fadeLeft: 700 }} delayIn={400} mounted={true} stay={true} className="logo overflow-visible padd-top-half">
                <>
                  {/* <Parallax className="star-parallax" translateY={[0, 20]} tagouter="figure"> */}
                    <div className="aspect-ratio">
                      <StaticImage
                        src={'../../static/images/graff_love_2024.jpg'}
                        // layout="fixed"
                        objectFit="cover"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt="Graff Love"
                      />
                    </div>
                  {/* </Parallax> */}
                </>
              </Intro>
            </div>
            <div className=' span-9 span-12-mobile padd-left-half padd-right-2 about-copy'>
              <Intro visible={refAboutInView} in={{ fadeRight: 700 }} delayIn={500} mounted={true} stay={true} className="overflow-visible stay-connected-wrap padd-top-half fs-125">
                <p className='padd-bottom-half'>
                  Irie Weekend is one of South Florida’s most buzz-worthy
                  and highly anticipated weekends of the year, which also stands as the Irie Foundation’s signature annual fundraiser.
                  The star-studded, non-stop weekend of festivities draws participants from near and far.
                </p>
                <p>For the past 18 years, Irie Weekend has brought together leading lifestyle brands, business executives, notable VIPs, celebrities, professional athletes, and world-renowned musical artists to create South Florida's biggest celebration, with proceeds benefiting the Irie Foundation.
                </p>
              </Intro>
            </div>
          </div>

        </section>

        <section ref={refPartners} className="section-partners c0 make-a-donation-follows">

          <div className="flex padd">
            <Intro visible={refPartnersInView} in={{ fadeRight: 700 }} delayIn={500} mounted={true} stay={true} className="overflow-visible stay-connected-wrap padd-top-half fs-125">
              <h2 className='h4'>
                Major Thank you
                to our partners!
              </h2>
            </Intro>
            <div className='flex-12 copy partners-grid'>
              {partners}
            </div>
          </div>

        </section>

        <section ref={refFoundation} className="section-foundation partner-with-us-follows">

          {refFoundationInView ?
            <a className='section-header-hero donation' href="https://www.iriefoundation.org/" target="_blank" rel="noreferrer noopener">
              <h2 className='purple'>
                <Intro visible={refFoundationInView} in={{ fadeBottom: 490 }} delayIn={0} mounted={true} stay={true} className="overflow-visible intro">
                  Make a donation<br/>irie foundation
                </Intro>
              </h2>
            </a>
            : null}

          <Intro visible={refFoundationInView} in={{ fade: 750 }} delayIn={750} mounted={true} stay={true} className="bg-intro">
            <Parallax className="star-parallax" translateY={[10, -10]} tagouter="figure">
              <div className='bg-container'></div>
            </Parallax>
          </Intro>

          <div className="flex padd-half padd-bottom-2">

            <div className="flex-12 jc-flex-end gallery">
              <div className="span-9 span-12-mobile overflow-hidden padd-right-half as-center">
                <Intro visible={refFoundationInView} in={{ bg: 750, fade: 750 }} delayIn={600} mounted={true} stay={true} className="c5 t">
                  <SanitySlider {...sanityPage?.foundation_slider?.[0]} />
                </Intro>
              </div>
            </div>

            <div className='flex-12 copy about-foundation'>
              <div className="span-6 span-12-mobile flex padd-half ac-center ai-center">
                <Intro visible={refFoundationInView} in={{ fadeLeft: 700 }} delayIn={400} mounted={true} stay={true} className="logo overflow-visible">
                  <>
                    <div className="aspect-ratio">
                      <StaticImage
                        src={'../../static/images/foundation-logo.png'}
                        layout="fixed"
                        objectFit="contain"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt="18th Annual Irie Weekend"
                      />
                    </div>
                  </>
                </Intro>
                <Intro visible={refFoundationInView} in={{ fadeRight: 700 }} delayIn={500} mounted={true} stay={true} className=" stay-connected-wrap padd-top-half fs-125">
                  <SanitySlider {...sanityPage?.foundation_copy?.[0]} />
                </Intro>
              </div>
            </div>

          </div>

          {refFoundationInView ?
            <div className='section-header-hero partner'>
              <h2 className='black'>
                <Intro visible={refFoundationInView} in={{ fadeBottom: 490 }} delayIn={100} mounted={true} stay={true} className="overflow-visible intro">
                  Partner with us
                </Intro>
              </h2>
            </div>
            : null}

        </section>

        <Footer />

      </main>


      {
        quickHTML ?
          <div
            dangerouslySetInnerHTML={{ __html: quickHTML }}
          />
          : null
      }

    </>
  )
}

export default Page

export const query = graphql`
  query {
    sanityPageHome {
      ticker
      hero_slider {
        ...sanitySlider
      }
      foundation_slider {
        ...sanitySlider
      }
      foundation_copy {
        ...sanitySlider
      }
      section_video {
        ...sanityVideo
      }
      section_tickets {
        ...sanitySlider
      }
      partners {
        image {
          ...imgFullSanity
        }
      }

      quickHTML {
        code
      }
    }
  }
`